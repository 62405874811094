@import 'style/_variables.scss';
@import 'style/_mixins.scss';

.person-tile {
  height: 25vw;
  max-height: 400px;
  width: 100%;
  overflow: hidden;
  position: relative;
  .person-tile-image {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
    @include smoothness(.2s);
  }
  &:hover {
    cursor: pointer;
    .person-tile-image {
      transform: scale(1.05);
      opacity: .5;
    }
    .person-tile-info {
      opacity: 1;
      transform: scale(1.1);
      background: rgba(255, 255, 255, 0.7);
    }
  }
}

.person-tile-info {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 3;
  opacity: 0;
  @include smoothness(.2s);
  @include noSelect;
}

@media only screen and (max-width: 1100px) {
  .person-tile-info {
    h4 {
      font-size: 2.8vw;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .person-tile {
    height: 33vw;
  }
  .person-tile-info {
    h4 {
      font-size: 3.4vw;
    }
  }
}

@media only screen and (max-width: 700px) {
  .person-tile {
    height: 50vw;
  }
  .person-tile-info {
    h4 {
      font-size: 4.8vw;
    }
  }
}

@media only screen and (max-width: 480px) {
  .person-tile-info {
    display: none;
  }
  .person-tile {
    height: 100vw;
  }
}