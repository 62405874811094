@import 'style/_variables.scss';
@import 'style/_mixins.scss';

.community-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1px;
  color: $black;
  background: $white;
  position: relative;
  .community-grid-title {
    color: $black;
    align-items: flex-start;
    justify-content: center;
    display: flex;
    flex-direction: column;
    content: "Community";
    width: 33.3333vw;
    height: 33vw;
    top: 0;
    left: 0;
    padding: 40px;
    .eyebrow {
      padding-bottom: 10px;
    }
  }
  .grid-item {
    height: 33vw;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .community-grid-item-content {
      max-width: 420px;
      padding: 40px;
      height: 100%;
      .community-header {
        @include smoothness(.2s);
        &:hover {
          color: $pop;
        }
      }
      p {
        letter-spacing: .025rem;
        line-height: 26px;
      }
    }
    &:nth-child(9n + 2){
      grid-column: 2;
    }
    &:nth-child(9n + 3){
      grid-column: 3;
    }
    &:nth-child(9n + 4){
      grid-column: 1;
    }
    &:nth-child(9n + 5){
      grid-column: 2;
    }
    &:nth-child(9n + 6){
      grid-column: 2;
    }
    &:nth-child(9n + 7){
      grid-column: 3;
    }
    &:nth-child(9n + 8){
      grid-column: 1;
    }
    &:nth-child(9n){
      grid-column: 2;
    }
    &:last-child {
      background: $secondary;
      grid-column: 3;
    }
    &:hover .grid-item-image,
    &:focus .grid-item-image {
      transform: scale(1.05);
    }
    .grid-item-content {
      padding: 60px;
      height: 100%;
      width: 100%;
    }
  }
  .grid-item-image {
    height: 100%;
    width: 100%;
    background-position: center;
    background-size: cover;
    @include smoothness(0.23s);
  }
}

@media only screen and (max-width: 1220px) {
  .community-grid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto ;
    .community-grid-title {
      width: 100vw;
      height: auto;
      grid-column: span 2;
    }
    .grid-item {
      height: 50vw;
      grid-column: auto !important;
      &:last-child {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 820px) {
  .community-grid {
    grid-template-columns: 1fr;
    .community-grid-title {
      grid-column: auto;
    }
    .grid-item {
      height: 100%;
      .grid-item-image {
        height: 100vw;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .community-grid {
    .community-grid-title {
      padding-left: 20px;
    }
  .grid-item 
    .community-grid-item-content {
      padding-left: 20px;
    }
  }
}