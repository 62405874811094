@import 'style/_variables.scss';
@import 'style/_mixins.scss';

.careers-page {
  .overhang-support-sidenote {
    margin-bottom: 0;
  }
  h5 {
    text-transform: none;
  }
  .community-grid {
    padding-top: 100px;
  }
  section {
    border: none;
  }
  .subscribe h3 {
    border-top: none;
  }
}

.careers-list-section {
  width: 100%;
  padding: 0 60px;
  border-bottom: 1px solid $grey-mid;
  max-width: 1320px;
  @include smoothness(.2s);
  h2 {
    padding-bottom: .5rem;
  }
}

.careers-list {
  padding: 40px 0 20px;
  .careers-list-title {
    padding-bottom: 20px;
  }
  .career-row {
    display: flex;
    flex-direction: row;
    padding: 30px;
    gap: 20px;
    &:nth-child(even){
      background: $grey-light;
    }
    .career-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex: 1;
      span {
        font-weight: 600;

      };
    }
    .career-location {
      display: flex;
      align-items: center;
      width: 200px;
    }
  }
  &:last-child {
    padding-bottom: 100px;
  }
}

.career-actions {
  .button {
    background: $secondary-light;
    margin: 0;
    &:hover {
      background: $pop;
      color: white;
    }
    &:active {
      background: $secondary-light;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .careers-list-section {
    padding: 0 40px;
    .career-row {
      .career-location {
        width: 180px;
      }
    }
  }
  
}

@media only screen and (max-width: 700px) {
  .careers-list-section {
    padding: 0 40px;
    .career-row {
      flex-direction: column
    }
  }
  
}

@media only screen and (max-width: 480px) {
  .careers-list-section {
    padding: 0 20px;
    .career-row {
      flex-direction: column
    }
  }
  
}