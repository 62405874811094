@import 'style/_variables.scss';
@import 'style/_mixins.scss';


.page-overhang {
  z-index: 2;
  margin: -60px 40px 60px;
  width: 100%;
  max-width: 1320px;
  flex-direction: row;
  @include smoothness(.2s);
}

.overhang-flex-container {
  display: flex;
}

.overhang-primary-content {
  background: white;
  flex: 1;
  padding: 120px 120px 0 60px;
  p a, li a {
    color: $pop;
    @include smoothness(.2s);
    &:hover {
      color: $secondary;
    }
  }
  .rule-orange {
    margin: 44px 0;
  }
  ul {
    font-size: 18px;
    padding: 2em 0 2em 40px;
    li {
      list-style: disc;
      line-height: 26px;
    }
  }
  @include smoothness(.2s);
}
.overhang-support-sidenote {
  width: 300px;
  padding-right: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  position: relative;
  @include smoothness(.2s);
  .sidenote-alt {
    position: absolute;
    top: 80px;
  }
  .sidenote-main {
    padding-bottom: 60px;
    flex: 1;
    h5 {
      padding-bottom: 20px;
    }
  }
  .support-sidenote-alt-text {
    padding-bottom: 1em;
  }
  .support-sidenote-credit {
    font-weight: 600;
    a {
      font-weight: 500;
      color: $pop;
      @include smoothness(.2s);
      &:hover {
        color: $secondary;
      }
    }
  }
  .button {
    margin: 0;
    width: min-content;
  }
  h5 {
    text-transform: capitalize;
  }
}

.overhang-primary-content.overhang-additional-content {
  margin-right: 300px;
  padding-top: 0px;
}

@media only screen and (max-width: 1000px) {
  .overhang-flex-container {
    flex-direction: column;
  }
  .overhang-primary-content {
    padding-left: 40px;
  }
  .overhang-support-sidenote {
    flex-direction: row;
    width: 100%;
    padding: 40px 40px 0;
    justify-content: center;
    align-items: center;
  }
  .overhang-primary-content.overhang-additional-content {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 700px) {
  .page-overhang {
    margin: 0;
    padding: 40px;
    background: #FFFFFF00;
  }
  .overhang-primary-content {
    padding: 60px 0;
  }
  .overhang-support-sidenote {
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
  }
}

@media only screen and (max-width: 480px) {
  .page-overhang {
    padding: 0;
  }

  .overhang-primary-content {
    padding: 40px 20px;
  }
  .overhang-support-sidenote {
    padding: 0 20px;
    .button {
      width: 100%;
    }
  }
}