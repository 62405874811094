@import 'style/_variables.scss';
@import 'style/_mixins.scss';

.downloads-section {
  max-width: 1200px;
  margin: 0 40px 80px;
  @include smoothness(.2s);
  .downloads-title-bar {
    background: $grey-light;
    height: 86px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    h5 { 
      width: 100%;
      padding: 0 40px;
      span {
        border-left: 1px solid grey;
        margin-left: 16px;
        padding-left: 16px;
        color: $pop;
        text-transform: lowercase;
      }
    }
  }
  .downloads-section-content {
    margin: 30px 40px 80px;
  }
}

.downloadable-files {
  display: flex;
  flex-direction: row;
  gap: 40px 20px;
  margin-top: 40px;
  min-width: 230px;
}

.downloadable-file {
  width: 100%;
  max-width: 270px;
  color: $secondary;
  p + p{
    margin: 0;
  }
  .button {
    margin-top: 0;
    flex-shrink: 0;
    .download-arrow {
      fill: $white;
      height: 10px;
      padding-left: 6px;
      @include smoothness(.2s);
      transform-origin: 10px center;
    }
    #download-arrow-up {
      transform: rotate(-90deg)
    }
    &:hover {
      .download-arrow {
        fill: $pop;
      }
      #download-arrow-down {
        transform: rotate(-45deg)
      }
      #download-arrow-up {
        transform: rotate(-45deg)
      }
    }
  }
}

.downloadable-file-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
}

.download-button-group {
  display: flex;
  flex-direction: row;
  gap: 10px;
  text-transform: uppercase;
  margin-top: 20px;
  .button {
    &:hover {
      box-shadow: 3px 3px 10px #00000052;
    }
    &:active {
      box-shadow: none;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .downloads-section {
    margin: 0 0 80px;
  }
  .download-button-group {
    .button {
      flex-grow: 0;
      width: auto;
      width: min-content;
      margin-top: 0px;
      flex-shrink: 0;
      height: 40px;
    }
  }
  .downloadable-file {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 920px) {
  .downloadable-files {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 700px) {
}

@media only screen and (max-width: 600px) {
  .download-button-group {
    flex-direction: column;
    flex: 1;
    justify-content: flex-end;
  }
  .downloads-section {
    margin: 0 0 80px;
  }
}

@media only screen and (max-width: 480px) {
  .downloads-section {
    .downloads-title-bar h5 {
      padding: 0 20px;
    }
    .downloads-section-content {
      margin: 30px 20px 80px;
    }
  }
}

@media only screen and (max-width: 400px) {
  .downloadable-files {
    display: flex;
    flex-direction: column;
  }
  .download-button-group {
    flex-direction: row;
    flex: unset;
    justify-content: flex-start;
  }
}