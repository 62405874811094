@import 'style/_variables.scss';
@import 'style/_mixins.scss';


.shop-feature {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 66vh 1fr;
  gap: 100px 60px;
  padding: 0 40px 200px;
  color: $black;
  background: $white;
  p {
    margin-bottom: 30px;
  }
  a {
    @include smoothness(.2s);
  }
  a:hover {
    color: $pop;

  }
  .shop-feature-img-container {
    grid-column: span 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    @include smoothness(.2s);
    &:hover .shop-feature-img,
    &:focus .shop-feature-img {
      transform: scale(1.05);
    }
    .shop-feature-img {
      width: 100%;
      height: 100%;
      max-width: 1111px;
      object-fit: contain;
      @include smoothness(.2s);
    }
  }
  .shop-feature-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $black;
    max-width: 300px;
  }
  .shop-featurette {
    display: flex;
    flex-direction: column;
    height: 100%;
    .shop-featurette-body {
      flex: 1;
    }
    a {
      @include smoothness(.2s);
    }
    a:hover {
      color: $pop;

    }
    .shop-featurette-img-container {
      .shop-featurette-img {
        width: 100%;
        object-fit: contain;
        object-position: center;
        @include smoothness(.2s);
      }
      padding-bottom: 14px;
      @include smoothness(.2s);
      &:hover .shop-featurette-img,
      &:focus .shop-featurette-img {
        transform: scale(1.05);
      }
    }
  }
}

@media only screen and (max-width: 1400px) {
  .shop-feature {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto 1fr;
    .shop-feature-img-container {
      grid-column: 1;
      padding-bottom: 40px;
    }
    .shop-featurette:last-child {
      grid-column: span 2;
      width: 50%;
      justify-self: center;
      .shop-featurette-img-container {
        height: 40vw;
        img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }
    }
    .shop-feature-text {
      max-width: unset;
    }
  }
}

@media only screen and (max-width: 800px) {
  .shop-feature {
    padding-top: 0;
    grid-template-columns: 1fr;
    grid-template-rows: 70vw 1fr;
    .shop-feature-img-container {
      grid-column: 1;
    }
    .shop-featurette:last-child {
      grid-column: 1;
    }
    .shop-featurette:last-child {
      grid-column: 1;
      width: 100%;
      .shop-featurette-img-container {
        height: unset;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .shop-feature {
    padding: 0 20px 100px;
    .shop-feature-img-container {
      padding-bottom: 0;
    }
  }
}