@import 'style/_variables.scss';
@import 'style/_mixins.scss';

.person-overlay-container {
  position: fixed;
  z-index: 99;
  top: 0;
  height: 100vh;
  width: 100vw;
  padding: 40px;
  overflow: auto;
}

.person-overlay {
  position: relative;
  background: white;
  height: auto;
  padding: 100px 40px 140px;
  height: auto;
  min-height: calc(100vh - 80px);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 40px;
  button {
    background: none;
    border: none;
  }
  .close-button {
  position: absolute;
  top: 40px;
  right: 40px;
  cursor: pointer;
  }
  h4{
    margin-bottom: .5rem;
  }
}

.person-overlay-image {
  height: auto;
  width: 50%;
  object-fit: contain;
  object-position: top;
  max-width: 600px;
}

.person-overlay-info {
  max-width: 600px;
  p {
    padding-bottom: 60px;
    letter-spacing: .03em;
  }
}

.overlay-scrim {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba($color: #000000, $alpha: 0.5);
  z-index: 4;
  cursor: pointer;
}

@media only screen and (max-width: 1000px) {
  .person-overlay {
    flex-direction: column;
  }
  .person-overlay-image {
  width: 100%;
  max-width: unset;
  }
}

@media only screen and (max-width: 480px) {
  .person-overlay-container {
    padding: unset;
  }
}