@import 'style/_variables.scss';
@import 'style/_mixins.scss';

.product-feature {
  background-position: center;
  background-size: cover;
  height: 60vw;
  gap: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.product-feature-textblock {
  margin-left: 60px;
  .galea-logo {
    height: 32px;
    margin-bottom: 50px;
  }
  a {
    padding-left: 38px;
  }
  color: white;
  max-width: 700px;
}

.product-feature-img-container {
  display: flex;
  height: 100%;
  align-items: center;
  transform: translateX(-1vw);
  .product-feature-img {
    height: 40vw;
    width: auto;
  }
}

.product-feature-title {
  text-transform: none;
  margin-bottom: 45px;
  padding-left: 38px;
  max-width: 400px;
}


@media only screen and (max-width: 1000px) {
  .product-feature {
    height: auto;
    padding: 60px 20px;
    flex-direction: column-reverse;
    gap: 60px;
    .product-feature-img-container {
      transform: translateX(unset);
    }
    .product-feature-textblock {
      margin-left: 0;
    }
  }
}

@media only screen and (max-width: 700px) {
  .product-feature {
    .product-feature-img-container {
      .product-feature-img {
        height: 60vw;
      }
    }
    .product-feature-title {
        max-width: none;
      }
  }
}

@media only screen and (max-width: 480px) {
  .product-feature {
    .product-feature-textblock {
      margin-left: 0;
    }
    .product-feature-title, .product-feature-textblock a {
      padding: 0;
    }
  }
}