@import 'style/_variables.scss';
@import 'style/_mixins.scss';

.subscribe {
  padding: 0 40px 90px;
  color: $black;
  background: $white;
  position: relative;
  h3 {
    border-top: 1px solid $grey-mid;
    padding-top: 90px;
  }
  @include smoothness(.2s);
  .subscribe-form-container {
    display: flex;
    flex-direction: row;
    margin-top: 1em;
    gap: 1.5em;
    position: relative;
    select {
      background: url("data:image/svg+xml;base64,PHN2ZyAKIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIgogd2lkdGg9IjIzcHgiIGhlaWdodD0iMTRweCI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgIGZpbGw9InJnYigyMjcsIDExOCwgMzcpIgogZD0iTTIyLjE4OCwyLjkwNyBMMTEuMzk4LDEzLjgzNSBMMTAuOTE3LDEzLjM1NCBMMTAuNzg3LDEzLjQ4MyBMMC4zMDksMi44ODQgTDIuMzAwLDAuODkzIEwxMS4yNjgsOS45NjMgTDIwLjE5NywwLjkxNSBMMjIuMTg4LDIuOTA3IFoiLz4KPC9zdmc+");
      background-repeat: no-repeat;
      background-position: center right;
      background-size: 18px;
      -moz-appearance: none; 
      -webkit-appearance: none; 
      appearance: none;
      &:hover {
        cursor: pointer;
      }
    }
    input, select {
      border: none;
      border-radius: unset;
      border-bottom: 1px solid $secondary-dark;
      height: 40px;
      width: 100%;
      max-width: 300px;
      flex: 1;
      padding-bottom: 6px;
      font-family: 'Montserrat', sans-serif;
    }
    .button {
      margin-top: 0;
      width: 200px;
      &:hover {
        color: $pop !important;
        box-shadow: 3px 3px 10px #00000052;
      }
      &:active {
        color: $white !important;
        background: $pop;
        box-shadow: unset;
      }
    }
  }
  .subscribe-status-message {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 1000px) {
  .subscribe {
    .subscribe-form-container {
      flex-direction: column;
      gap: 2em;
      input, select {
        max-width: none;
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .subscribe {
    padding: 0 40px 60px;
    h3 {
      padding-top: 60px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .subscribe {
    padding: 0 20px 40px;
    h3 {
      padding-top: 40px;
    }
      .subscribe-form-container .button {
      width: 100%;
  }
  }
}


