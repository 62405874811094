@import 'style/_variables.scss';
@import 'style/_mixins.scss';

.donate-page {
  form {
    width: calc(100% - 40px);
    margin: 0 auto 0 auto;
  }
}
  label {
    color: #6b7c93;
    font-weight: 300;
    letter-spacing: 0.025em;
    margin-top: 16px;
    display: block;
  }