/*
 * Typography
 *
 * Website-wide text styling
 *
 */

@import 'variables.scss';
@import 'mixins.scss';
//source-sans
@import url('https://fonts.googleapis.com/css2?family=Fira+Code&display=swap');

//PROJECT FONTS
//gideon: Nexa Black
//c4coin: Korolev Bold
@import url("https://use.typekit.net/kfb0eck.css");
//spark: Montserrat Regular
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;500;600;700;800&display=swap');

* {
	font-size: inherit;
	font-weight: inherit;
  color: inherit;
  vertical-align: inherit;
  line-height: 1.2em;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Montserrat", "sans-serif";
}

body  {
  font-family: "Montserrat", "sans-serif";
}

a {
	color: inherit;
	text-decoration: none;
}

strong {
  font-weight: 600;
  font-family: "Montserrat", "sans-serif";
}

h1,  h2,  h3,  h4,  h5,  h6 {
  font-weight: normal;
	margin: 0px;
}

p + p {
	margin-top: .75em;
}

li + li {
  margin-top: .5em;
}

h1 {
  font-size: 56px;
  line-height: 60px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: .025rem;
}

h2 {
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  text-transform: uppercase;
  letter-spacing: .025rem;
}

h3 {
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  text-transform: uppercase;
  letter-spacing: .025rem;
}

h4 {
  font-size: 28px;
  font-weight: 800;
  line-height: 36px;
  text-transform: uppercase;
}

.hero-eyebrow {
  font-size: 21px;
  font-weight: 700;
  text-transform: uppercase;
}

h5 {
  font-size: 26px;
  line-height: 34px;
  font-weight: 600;
  text-transform: uppercase;
}

h6 {
  font-size: 21px;
  line-height: 24px;
  font-weight: 600;
  text-transform: uppercase;
}

.eyebrow {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: $pop;
}

p {
  font-size: 18px;
  line-height: 26px;
  letter-spacing: .025rem;
  font-weight: 400;
  margin: 0px;
  strong {
    font-weight: 600;
  }
  a {
    color: $pop;
    @include smoothness (.2s);
    &:hover {
      color: $secondary-pop;
    }
  }
}

@media only screen and (max-width: 700px) {
  h1 {
    font-size: 8vw;
    line-height: 8.1vw;
    margin-bottom: 2vw !important;
  }
  .product-feature-textblock h2 {
    font-size: 8vw;
    line-height: 8.1vw;
  }
}