@import 'style/_variables.scss';
@import 'style/_mixins.scss';

.case-studies-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1px;
  color: $black;
  background: $white;
  .grid-item {
    height: 28vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 40px;
    position: relative;
    overflow: hidden;
    .grid-item-image {
      position: absolute;
      background-position: center;
      background-size: cover;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      @include smoothness(0.23s);
    }
    &:hover .grid-item-image,
    &:focus .grid-item-image {
      transform: scale(1.05);
    }
    .eyebrow {
      padding-bottom: 10px;
    }
    h4 {
      color: $white;
      z-index: 2;
    }
    &:first-child {
      justify-content: center;
      padding-right: 80px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .case-studies-grid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-auto-flow: column;
  }
}

@media only screen and (max-width: 850px) {
  .case-studies-grid .grid-item {
    height: unset;
  }
  .case-studies-grid {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 1fr);
  }
}

@media only screen and (max-width: 480px) {
  .case-studies-grid .grid-item {
    height: unset;
    padding-left: 20px;
  }
}