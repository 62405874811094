@import 'style/_variables.scss';
@import 'style/_mixins.scss';

.gssb_c .gsc-completion-container {
    position: fixed !important;
}

.search-container{
    z-index: 3;
    width: 100%;
    position: fixed;
    padding-top: 68px;
    transform: translateY(-132px);
    background: $secondary-dark;
    @include smoothness(.2s);
    #___gcse_0 {
    }
    &.active {
        transform: translateX(0px);
    }
    .gsc-control-searchbox-only {
        font-family: Montserrat;
        padding: none;
        .gsc-modal-background-image-visible.gsc-modal-background-image {
            display: none;
        }
        .gsc-input {
            font-family: 'Montserrat';
            font-weight: 500;
            padding-right: 0;
        }
        .gsc-input-box {
            border-radius: 20px 0 0 20px;
            border: none;
            height: 30px;
            padding-top: 0;
			padding-bottom: 0;
        }
        .gsc-search-box {
            padding: 6px 20px;
        }
        .gsc-search-button {
            border-radius: 0 20px 20px 0;
            margin-left: 0px;
        }
        .gsc-search-button-v2 {
            padding: 8.5px 27px;
            border: none;
            width: 100%;
            cursor: pointer;
            svg {
                width: auto !important;
            }
        }
    }
}


