@import 'style/_variables.scss';
@import 'style/_mixins.scss';

.header {
  background-color: $secondary-dark;
  position: fixed;
  top: 0;
  z-index: 4;
  width: 100%;
  padding: 10px 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .st0{fillRule:evenodd;clipRule:evenodd;fill:#FFFFFF;}
  transition: background-color 0.5s ease-in-out;
  &.invisible-header {
    background-color: rgba(1, 19, 38, 0);
  }
  .header-link {
    color: $white;
    padding: 16px;
    font-size: .875em;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: .05em;
    @include smoothness(.2s);
    &:hover {
      color: $pop;
    }
    &:active {
      color: $pop;
    }
    &.active {
      color: $pop;
    }
    span {
      color: $pop;
      padding-right: 4px;
    }
  }
  .header-logo-container {
    height: 40px;
  }
  .header-logo {
    fill: white;
    height: 40px;
    enable-background: new 0 0 442 100;
    #logo-gear {
      transform-origin: 50px 50px;
    }
    &:hover {
      #logo-gear {
        -webkit-animation: spin 4s linear infinite;
        -moz-animation: spin 4s linear infinite;
        animation: spin 4s linear infinite;
      }
    }
  }
  .search-icon {
    height: 20px;
    @include smoothness(.2s);
  }
  .search-button-container {
    background: none;
    border: none;
    padding: 14px 16px 11px;
    &:hover {
      cursor: pointer;
      .search-icon {
        fill: $pop;
      }
    }
  }
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.header-spacer {
  width: 140px;
}

.header-button {
  color: $white;
  background: $pop;
  border-radius: 8px;
  padding: .625em 2em;
  font-size: .825em;
  font-weight: 500;
  text-transform: uppercase;
  font-family: $montserrat;
  &:hover {
    color: white;
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
  &:active {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }
}

.product-title {
  top: 12px;
  color: $white;
  text-transform: uppercase;
  margin-left: .35em;
  span {
    font-size: .875em;
    -webkit-text-stroke-width: .2px;
    -webkit-text-stroke-color: white;
  }
  @include glow;
}

.header-links {
  align-self: right;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @include smoothness(.2s);
  .header-button {
    margin-left: 1em;
    display: inline-block;
  }
}

.hamburger {
  height: 48px;
  cursor: pointer;
  display: none;
  &svg {
    width: 200px;
    height: 150px;
    cursor: pointer;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  path {
    fill: none;
    -webkit-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke 0.2s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    -moz-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke 0.2s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    -o-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke 0.2s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    -ms-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke 0.2s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke 0.2s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    stroke-width: 20px;
    stroke-linecap: round;
    stroke: $white;
    stroke-dashoffset: 0px;
  }
  path#top,
  path#bottom {
    stroke-dasharray: 240px 950px;
  }
  path#middle {
    stroke-dasharray: 240px 240px;
  }
  
  &.cross path#top,
  &.cross path#bottom {
    stroke-dashoffset: -650px;
    stroke-dashoffset: -650px;
  }
  &.cross path#middle {
    stroke-dashoffset: -115px;
    stroke-dasharray: 1px 220px;
  }
  &:hover{
    path {
      stroke: $pop;
    }
  }
}

// fix for shrinking header
.header-bg {
  height: 68px;
  background-color: $secondary-dark;
  z-index: -5;
  position: absolute;
  top: 0;
  width: 100%;
}

@media only screen and (max-width: 1000px) {

  .header-links {
    display: flex;
    background: $secondary-dark;
    height: auto;
    width: 100%;
    align-items: center;
    flex-direction: column;
    top: -100vh;
    position: absolute;
    left: 0px;
    padding-top: 84px;
    padding-bottom: 2em;
    z-index: -1;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    border-bottom: solid 1px rgba(0,0,0,.1);
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.05);
    -webkit-transition: 0.5s top ease-in-out;
    -moz-transition: 0.5s top ease-in-out;
    -o-transition: 0.5s top ease-in-out;
    -ms-transition: 0.5s top ease-in-out;
    transition: 0.5s top ease-in-out;
    .header-link {
      padding: 20px;
      &:hover {
        color: $pop;
      }
    }
  }

  .header-links.isActive {
    top: 0;
  }

  .desktop-links {
    display: none;
  }
  .hamburger{
    display: inline-block;
  }
}

@media only screen and (max-width: 480px) {
  .header {
    padding: 6px 20px;
  }
  .header-logo-container {
    width: 50px;
    overflow: hidden;
  }
  #logo-text {
    display: none;
  }
}

.ReactCollapse--collapse {
  transition: height 500ms;
}

.ReactCollapse--content {
  top: 0;
  z-index: 2;
  width: 100%;
  position: sticky;
  padding-top: 68px;
}
