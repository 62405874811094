@import 'style/_variables.scss';
@import 'style/_mixins.scss';

.search-results-container {
  padding-top: 68px;
  width: 100%;
  min-height: calc(100vh - 260px);
  background-color: $black;
  .page-content-wrapper{
    border-bottom: none;
  }
  #___gcse_1 {
    width: 100%;
    .gsc-control-cse {
      background: white;
      border: white;
      .gsc-selected-option-container {
        padding-top: 6px;
      }
    }
  }
  .gsc-results-wrapper-overlay {
    background: $black;
        .gsc-wrapper {
            a.gs-title:link {
                color: $pop;
                // THIS IS THE COLOR OF THE WORD IN SEARCH
                b {
                    color: $pop
                }
            }
        }
    }
    .gsc-modal-background-image-visible.gsc-modal-background-image {
      display: none;
  }
  .gsc-input {
      font-family: 'Montserrat';
      font-weight: 500;
      padding-right: 0;
  }
  .gsc-input-box {
      border-radius: 20px 0 0 20px;
      border: none;
      padding-top: 0;
			padding-bottom: 0;
  }
  .gsc-search-button {
      border-radius: 0 20px 20px 0;
      margin-left: 0px;
  }
  .gsc-search-button-v2 {
      padding: 8px 27px;
      border: none;
      width: 100%;
      svg {
        width: auto !important;
    }
  }

}
