@import 'style/_variables.scss';
@import 'style/_mixins.scss';


.page-hero {
  height: 65vh;
  width: 100%;
  color: $white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  background-color: $secondary-dark;
  background-position: center top;
  background-size: cover;
  padding: 0 40px 80px;
  position: relative;
  margin-top: 60.8px;
  h1 {
    max-width: 800px;
    z-index: 2;
  }
  .hero-eyebrow { 
    z-index: 2;
    margin-bottom: 20px;
  }
  .button {
    z-index: 2;
  }
  .hero-scrim {
    position: absolute;
    height: 100%;
    width: 100%;
    background: radial-gradient(
      farthest-side at bottom left,
      rgba(0, 0, 0, 0.2), 
      transparent
    );
    left: 0;
    top: 0;
    z-index: 1;
  }
}

@media only screen and (max-width: 480px) {
  .page-hero {
    padding: 0 20px 40px;
  }
}