@import 'style/_variables.scss';
@import 'style/_mixins.scss';

.citations {
  background: $secondary;
  color: $white;
  padding: 130px 0;
  h2 {
    color: $white;
    margin-left: 90px;
    span {
      color: $pop;
    }
  }
  #citation-counter {
    display: inline;
  }
  .citations-list {
    display: flex;
    flex-direction: row;
    margin: 0 60px;
    .citation {
      padding: 36px;
      color: $white;
      h3 {
        margin-top: 20px;
        @include smoothness(.2s);
      }
      &:hover {
        h3 {
          color: $pop;
        }
      }
    }
  }
  .slick-slider {
    .slick-prev, .slick-next {
      background-image: url("../../../img/arrow-white.png");
      background-position: center;
      background-size: contain;
      &:hover {
        background-image: url("../../../img/arrow-orange.png");
      }
      &:active {        
        background-image: url("../../../img/arrow-white.png");
      }
    }
  }
  .text-arrow-link {
    padding-left: 90px;
  }
}

@media only screen and (max-width: 700px) {
  .citations {
    padding: 60px 0;
    h2 {
      margin-left: 40px;
    }
    .citations-list {
      margin: 0 40px;
      .citation {
        padding: 36px 0px;
      }
    }
    .text-arrow-link {
      padding-left: 40px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .citations {
    h2 {
      margin-left: 20px;
    }
    .citations-list {
      margin: 0 20px;
      .citation {
        padding: 36px 0px;
      }
    }
    .text-arrow-link {
      padding-left: 20px;
    }
  }
}