@import 'style/_variables.scss';
@import 'style/_mixins.scss';

.citations-section {
  max-width: 1200px;
  margin: 30px 0 80px;
  @include smoothness(.2s);
  & + .citations-section {
    margin-top: 60px;
    @include smoothness(.2s);
  }
}

.citations-table {
  text-align: left;
  p + p{
    margin-top: 0;
  }
}

.citations-table-header, .citation-list-item {
  gap: 60px;
  display: grid;
  grid-template-columns: 260px 1fr 300px;
}

.citations-table-header {
  border-bottom: 1px solid $grey-mid;
  padding: 16px 0;
  p {
    font-weight: 500;
  }
}
.citation-mobile-header {
  display: none;
}

.citation-list-item {
  border-bottom: 1px solid $grey-mid;
  line-height: 26px;
  padding: 30px 0;
  @include smoothness(.2s);
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    color: $pop;
  }
}

.see-more-button {
  background: none;
  border: none;
  padding: 2em 0;
}

@media only screen and (max-width: 1000px) {
  .citations-table-header, .citation-list-item {
    gap: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    p {
      font-size: 16px;
    }
  }
}

@media only screen and (max-width: 700px) {
  .citations-table-header{
    display: none;
  }

  .citation-mobile-header {
    display: block;
    font-weight: 500;
  }

  .citation-list-item {
    border-radius: 8px;
    grid-template-columns: 100px 1fr;
    padding: 20px 0;
  }
}

@media only screen and (max-width: 480px) {
.citation-list-item {
    grid-template-columns: 1fr;
  }
}