@import 'style/_variables.scss';
@import 'style/_mixins.scss';

.home-feature {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  height: 60vw;
  width: 100%;
  position: relative;
  .home-feature-background {
    background-position: center;
    background-size: cover;
    height: 100%;
    width: 100%;
  }
  .home-feature-card {
    position: absolute;
    right: calc(60vw);
    padding: 60px;
    top: 40%;
    color: white;
    background: rgba(3, 10, 16, 0.9);
    max-width: 600px;
    hr {
      border-color: $pop;
      border-top: 2px solid $pop;
    }
    p {
      font-weight: 500;
      padding-bottom: 30px;
    }
  }
}

@media only screen and (max-width: 1600px) {
  .home-feature {
    .home-feature-card {
      left: 40px;
      right: unset;
    }
  }
}

@media only screen and (max-width: 1280px) {
  .home-feature {
    align-items: center;
    height: 768px;
    .home-feature-background {
      padding: 20px;
      background-position: left;

    }
  }
}

@media only screen and (max-width: 850px) {
  .home-feature {
    align-items: center;
    .home-feature-background {
      background-position: top center;
    }
    .home-feature-card {
      padding: 60px 40px;
      top: unset;
      right: unset;
      left: unset;
      width: 100%;
      max-width: unset;
      position: relative;
      background: $black;
      color: $white;
      p {
        display: none;
      }
      .button {
        margin-top: 10px;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .home-feature {
    padding: 0;
    .home-feature-card {
      padding: 40px 20px;
      border-radius: 0;
    }
  }
}