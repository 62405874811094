@import 'style/_variables.scss';
@import 'style/_mixins.scss';

.donor-list {
  width: 100%;
  max-width: 1320px;
}

.donor-title-bar {
  background: $grey-light;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  padding-right: 40px;
  @include smoothness(.2s);
  h5 { 
    gap: 30px;
    flex: 1;
    text-transform: capitalize;
    padding: 27px 0;
  }
  .button {
    margin: 0;
  }
}

.donor-title-image {
  margin-right: 20px;
  height: 90px;
  align-self: flex-start;
}

.donor-title {
  color: $pop;
}

.donor-nickname {
  color: $secondary-dark;
  border-left: 1px solid grey;
  margin-left: 16px;
  padding-left: 16px;
}

.donor-list {
  padding: 30px 40px 80px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  @include smoothness(.2s);
  p + p {
    margin-top: 0;
  }
}

@media only screen and (max-width: 1220px) {
  .donor-nickname {
    display: block;
    border: none;
    margin: 0;
    padding: 0;
  }
}

@media only screen and (max-width: 1000px) {
  .donor-list-container {
    padding: 0;
  }
}

@media only screen and (max-width: 800px) {
  .donor-title-bar {
    display: grid;
    grid-template-columns: 125px 1fr;
    gap: 20px;
    padding-bottom: 27px;
    .donor-title-image {
      grid-row: span 2;
    }
    h5 {
      padding-bottom: 0;
    }
    .button {
      width: min-content;
    }
  }
}

@media only screen and (max-width: 800px) {
  .donor-list {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 480px) {
  .donor-title-bar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 20px 27px;
    .donor-title-image {
      margin-left: -20px;
    }
    h5 {
      padding-top: 0;
    }
  }

  .donor-list {
    padding: 30px 20px 60px;
  }
}