@import 'style/_variables.scss';
@import 'style/_mixins.scss';

.full-page-loader {
  max-height: 100vh;
  width: 100vw;
  // iOS hotfix
  min-height: 100vh;
  min-height: -webkit-fill-available;
  position: absolute;
  background-color: $secondary-dark;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  .loader-logo {
    height: 60px;
    width: 60px;
    fill: white;
    #loader-logo-gear {
      transform-origin: 50px 50px;
      -webkit-animation: spin 8s linear infinite;
      -moz-animation: spin 8s linear infinite;
      animation: spin 8s linear infinite;
    }
  }
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
