@import 'style/_variables.scss';
@import 'style/_mixins.scss';

.people-section {
  .page-overhang {
    margin-top: 0px;
  }
}

.people-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-auto-rows: auto;
  max-width: 2000px;
  margin: 0 auto;
}

.pc-icon-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 80px 40px;
  justify-content: space-between;
  align-items: center;
  .icon-grid-logo {
    height: 100%;
    width: 100%;
    max-height: 70px;
    flex: 1;
    object-fit: contain;
    filter: saturate(0);
    &:hover {
      filter: saturate(1);
    }
  }
}

.pc-table {
  display: flex;
  flex-direction: row;
  gap: 80px 20px;
  flex-wrap: wrap;
  // .pc-table-column {
  //   padding-bottom: 20px;
  // }
  .pc-table-header {
    font-weight: 600;
    padding-bottom: 30px;
  }
  .pc-table-column {
    flex: 1;
  }
  .pc-table-item, .pc-table-link {
    margin-top: 0.75em;
  }
  .pc-table-link {
    color: $pop;
    font-weight: 600;
    margin-top: 0.75em;
    @include smoothness(.2s);
    &:hover {
      color: $secondary;
    }
  }
}


@media only screen and (max-width: 1000px) {
  .people-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 700px) {
  .people-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 480px) {
  .people-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .icon-grid-logo {
    margin: 0 auto;
  }

  .pc-icon-grid {
    flex-direction: column;
  }
}