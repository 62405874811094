@import 'style/_variables.scss';
@import 'style/_mixins.scss';

.case-study-page {
  .rule-grey {
    height: 1px;
    color: $grey-mid;
  }
  .overhang-primary-content {
    padding-bottom: 80px;
    margin-bottom: 40px;
    border-bottom: 1px solid $grey-mid;
  }
}