@import 'style/_variables.scss';
@import 'style/_mixins.scss';


.testimonials {
  padding: 100px 0 0;
  color: $black;
  background: $white;
  > .testimonial-header {
    margin-left: 40px;
  }
  .testimonial-slider {
    margin: 0 60px;
    .testimonial {
      display: flex !important;
      flex-direction: row;
      padding: 80px 60px;
      .testimonial-image {
        width: 200px;
        height: 200px;
        margin-right: 20px;
        margin-bottom: 20px;
        grid-column: 1;
        border-radius: 100%;
        object-fit: cover;
      }
      .eyebrow {
        color: $pop;
        margin-bottom: 20px;
      }
      p {
        font-size: 26px;
        line-height: 36px;
        font-weight: 200;
        color: #030a12
      }
    }
  }
  .slick-slider {
    .slick-prev, .slick-next {
      background-image: url("../../../img/arrow-orange.png");
      background-position: center;
      background-size: contain;
      @include smoothness(.2s);
      &:hover {
        background-image: url("../../../img/arrow-white.png");
      }
      &:active {        
        background-image: url("../../../img/arrow-orange.png");
      }
    }
    .slick-prev:hover {
      box-shadow: -3px -3px 10px #00000052;
    }
    .slick-next:hover {
      box-shadow: 3px 3px 10px #00000052;
    }

    .slick-next:active, .slick-prev:active {
      box-shadow: none;
    }
  }
}

@media only screen and (max-width: 1400px) {
  .testimonials {
    .testimonial-slider {
      .testimonial {
        .testimonial-image {
          width: 14vw;
          height: 14vw;
        }
      }
    }
  }
}

@media only screen and (max-width: 1400px) {
  .testimonials {
    .testimonial-slider {
      .testimonial {
        flex-direction: column;
        .testimonial-image {
          width: 100px;
          height: 100px;
        }
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .testimonials {
    > .testimonial-header {
    margin-left: 20px;
    }
    .testimonial-slider {
      margin: 0;
      .testimonial {
        padding: 60px 20px;
      }
    }

  }
}