@import 'style/_variables.scss';
@import 'style/_mixins.scss';

.career-page {
  .rule-grey {
    height: 1px;
    color: $grey-mid;
  }
  .page-overhang {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 1000px) {
  .page-overhang .sidenote-main {
    padding-bottom: 20px;
  }
}