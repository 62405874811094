@import "./_variables.scss";

@mixin smoothness($time) {
  -webkit-transition: $time cubic-bezier(0.25, -0.25, 0.75, 1.25);
  -moz-transition: $time cubic-bezier(0.25, -0.25, 0.75, 1.25);
  -o-transition: $time cubic-bezier(0.25, -0.25, 0.75, 1.25);
  -ms-transition: $time cubic-bezier(0.25, -0.25, 0.75, 1.25);
  transition: $time cubic-bezier(0.25, -0.25, 0.75, 1.25);
}

@mixin noSelect {
  pointer-events: none;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

@mixin fillAvail {
  width: 100%;
  width: -moz-available;          /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
  width: stretch;
}
@mixin glow {
  -webkit-animation: glow 2s ease-in-out infinite alternate;
  -moz-animation: glow 2s ease-in-out infinite alternate;
  animation: glow 2s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    text-shadow: 0 0 2px #fff, 0 0 4px #fff, 0 0 6px #9AADCE, 0 0 8px #9AADCE, 0 0 10px #9AADCE, 0 0 12px #9AADCE, 0 0 14px #9AADCE;
  }
  to {
    text-shadow: 0 0 4px #fff, 0 0 8px #fff, 0 0 12px #9AADCE, 0 0 16px #9AADCE, 0 0 20px #9AADCE, 0 0 24px #9AADCE, 0 0 28px #9AADCE;
  }
}

$breakpoints: (
  "sm": $bp-sm,
  "md": $bp-md,
  "lg": $bp-lg,
  "xl": $bp-xl
)