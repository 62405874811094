@import 'style/_variables.scss';
@import 'style/_mixins.scss';

.footer {
  background-color: $secondary-dark;
  z-index: 4;
  width: 100%;
  padding: 120px 40px 34px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  .footer-links {
    align-self: right;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @include smoothness(.2s);
  }
  .footer-link {
    color: $white;
    padding: 16px;
    font-size: 12px;
    text-transform: uppercase;
    font-family: $montserrat;
    font-weight: 500;
    letter-spacing: .125em;
    @include smoothness(.2s);
    &:hover, &:active, &.active {
      color: $pop;
    }
    span {
      color: $pop;
      padding-right: 4px;
    }
  }
  .footer-logo {
    fill: white;
    height: 30px;
    @include smoothness(.2s);
    &:hover {
      fill: $pop;
    }
  }
  .footer-socials {
    font-weight: 500;
    color: $pop;
    display: flex;
    align-items: center;
    gap: .5em;
    p {
      color: grey;
    }
    * {
      display: inline-block;
    }
    a {
      @include smoothness(.2s);
      &:hover {
        color: $white;
      }
    } 
  }
}

.sub-footer {
  background-color: $secondary-dark;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1em;
  padding: 0 40px 40px;
  p,a {
    font-size: 12px;
    line-height: 24px;
    color: #78838C;
  }
  .sub-footer-link {
    &:hover, &:active, &.active {
      color: $pop;
    }
  }
}

@media only screen and (max-width: 1400px) {
  .footer {
    flex-direction: column;
  }
  .sub-footer {
    justify-content: center;
  }
}

@media only screen and (max-width: 1100px) {
  .footer .footer-links {
    flex-direction: column;
  }
  .sub-footer {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}