@import 'style/_variables.scss';
@import 'style/_mixins.scss';

.citation-list-container {
  width: 100%;
  max-width: 1320px;
  padding: 0 40px;
  flex: 1;
}

.citation-list-header {
  @include smoothness(.2s);
}

.citation-list-header-title {
  margin-bottom: 1rem;
}

@media only screen and (max-width: 1000px) {

}

@media only screen and (max-width: 700px) {
.citation-list-container {
  padding: 0 40px;
}
}

@media only screen and (max-width: 480px) {
.citation-list-container {
  padding: 0 20px;
}
.citation-list-header {
  padding: 0;
}
}