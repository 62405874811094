@import 'style/_variables.scss';
@import 'style/_mixins.scss';

.custom-hero {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: calc(100vh + 1px);
  min-height: 600px;
  position: relative;
  z-index: 1;
  background: $black;
  // Hide scrollbar FOIC
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  h1 {
    max-width: 800px;
    z-index: 2;
    color: $white;
  }
  .hero-eyebrow { 
    color: $pop;
    z-index: 2;
    margin-bottom: 20px;
  }
  .button {
    z-index: 2;
    margin-top: 10px;
  }
  .hero-scrim {
    position: absolute;
    height: 100%;
    width: 100%;
    background: radial-gradient(
      farthest-side at bottom left,
      rgba(0, 0, 0, 0.2), 
      transparent
    );
    left: 0;
    top: 0;
    z-index: 1;
  }
}

.hero::-webkit-scrollbar {
  display: none;
}

.hero-content-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0 40px 100px;
  position: relative;
}

.hero-img-container {
  position: absolute;
  padding: 30px 8vw 220px 30vw;
  height: 100%;
}

.hero-img {
  pointer-events: none;
  object-fit: contain;
  justify-self: flex-start;
  align-self: flex-end;
  min-width: 0;
  flex: 1;
  width: 100%; 
  height: 100%;
}

.hero-video-container {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  overflow: hidden;
  z-index: -13;
  background-color: $secondary-dark;
  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    content:'';
    background: linear-gradient(to bottom, #01060B00, #01060B);
  }
}

.hero-video {
  width: 100%;
  height: 100%;
  opacity: .6;
  object-fit: cover;
  bottom: 0;
  overflow: hidden;
  video {
    height: 100%;
    width: 177.77777778vh; /* 100 * 16 / 9 */
    min-width: 100%;
    min-height: 56.25vw; /* 100 * 9 / 16 */
    position: absolute;
    left: 50%; /* % of surrounding element */
    top: 50%;
    transform: translate(-50%, -50%); /* % of current element */
}
}

.hero-down-arrow-container {
  position: absolute;
  padding: 20px;
  bottom: 30px;
  margin: 0 auto;
  left: calc(50% - 33px);
  z-index: 999;
  background: none;
  border: none;
  cursor: pointer;
}
.hero-down-arrow {
  width: 30px;
  fill: white;
}

@media only screen and (max-height: 860px) {
  .custom-hero {
    min-height: 800px;
  }
  .hero-img {
    height: 100%;
  }
  .hero-container {
    max-width: 900px;
  }
}

@media only screen and (max-width: 740px) {
  .custom-hero {
    height: 170vw;
    max-height: 100vh;
    justify-content: flex-start;
    .hero-img-container {
      padding-bottom: calc(200px);
      max-height: calc(100% - 150px);
    }
  }
  .custom-hero h1 {
    font-size: calc(.08 * (100vw - 80px));
  }
}

@media only screen and (max-width: 480px) {
  .custom-hero {
    min-height: unset;
    justify-content: center;
    .hero-content-container {
      padding: 0 20px 60px;
    }
  }
  .hero-down-arrow {
    width: 26px;
    left: calc(50% - 13px);
  }
  .hero-down-arrow-container {
    bottom: 0px;
  }
}

@media only screen and (max-width: 480px) {
  .custom-hero h1 {
      font-size: calc(.095 * (100vw - 80px));
    }
}

@media only screen and (max-width: 300px) {
  .custom-hero {
    height: 170vh;
    .hero-content-container {
      padding: 0 20px 60px;
    }
  }
}

/* Maximum aspect ratio */
@media (max-aspect-ratio: 5/4) {
  .hero-img-container {
    padding: 0 4vw 320px;
  }
}

@media only screen and (min-height: 1000px) and (max-aspect-ratio: 4/3) {
  .hero-img-container {
    padding: 0 4vw 320px;
  }
}
