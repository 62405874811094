@import 'style/_variables.scss';
@import 'style/_mixins.scss';

.page-content-section-header {
  width: 100%;
}

.about-page {
  .subscribe h3 {
    border-top: none;
  }
}