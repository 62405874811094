@import 'style/_variables.scss';
@import 'style/_mixins.scss';

.donation-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  position: relative;
  max-width: 1420px;
  h3 {
    color: $white;
  }
  .rule-white, .rule-orange {
    height: 3px;
  }
}

.donation-section-orange, 
.donation-section-blue {
  flex: 1;
  padding: 80px 40px 70px;
  display: flex;
  flex-direction: column;
}

.donation-section-orange {
  background: $pop;
  .donation-bar-button {
    background: $secondary-dark;
    color: $white;
    &:hover {
      background: $white;;
      color: $secondary-dark;
    }
    &:active {
      background: $secondary-dark;
      color: $white;
    }
  }
}

.donation-section-blue {
  background: $secondary;
  color: $white;
  .donation-bar-button {
    background: $pop;
    &:hover {
      background: $white;
      color: $pop;
    }
    &:active {
      background: $pop;
      color: $white;
    }
  }
  .donation-copy {
    padding-bottom: 40px;
    flex: 1;
  }
}

.donation-amount-buttons {
  display: flex;
  gap: 12px;
  width: 100%;
  padding: 25px 0 50px 0;
}

.donation-amount-button {
  flex: 1;
  padding: 14px 0;
  background: $white;
  color: $pop;
  border: none;
  border-radius: 14px;
  font-size: 24px;
  font-weight: 600;
  font-family: 'montserrat';
  &:hover {
    cursor: pointer;
  }
  &.active {
    background: $secondary-dark;
    color: $white;
  }
}

.donation-bar {
  height: 56px;
  display: flex;
  flex-direction: row;
  width: 100%;
  font-weight: 600;
  gap: 30px;
  .donation-bar-field {
    flex: 1;
    border-bottom: 1px solid $white;
    font-size: 36px;
    display: flex;
    align-items: center;
  }
}

.donation-bar-button {
  border-radius: 14px;
  font-size: 24px;
  padding: 14px 20px;
  border: none;
  font-family: 'montserrat';
  @include smoothness(.2s);
  &:hover {
    cursor: pointer;
  }
}

.donate-caveat {
  width: 100%;
  margin-bottom: 60px;
  padding: 0 40px;
  max-width: 1500px;
}

@media only screen and (max-width: 1000px) {
  .donation-section {
    flex-direction: column;
  }
}

@media only screen and (max-width: 480px) {
  .donation-amount-buttons {
    flex-direction: column;
    .donation-amount-button {
      height:100px;
    }
  }
  .donation-bar {
    flex-direction: column;
    height: auto;
  }
  .donate-caveat {
    padding: 0 20px;
  }
}